var _paq = window._paq = window._paq || [];
<!-- track time spent by visitors on page -->
_paq.push(['enableHeartBeatTimer', 30]);
<!-- campaign name parameter used in tracking urls -->
_paq.push(['setCampaignNameKey', 'c']);
<!-- campaign key word parameter used in tracking urls -->
_paq.push(['setCampaignKeywordKey', 'k']);
<!-- respect DNT Header -->
_paq.push(['setDoNotTrack', true]);
<!-- disable all matomo cookies -->
_paq.push(['disableCookies']);
<!-- enable tracking when matomo.js is loaded on a page -->
_paq.push(['trackPageView']);
<!-- track marked up content if visible to visitor -->
_paq.push(['trackVisibleContentImpressions']);
<!-- track outlinks and downloads -->
_paq.push(['enableLinkTracking']);
(function() {
    var u="https://matomo.testfabrik.com/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    <!-- Matomo Production -->
    _paq.push(['setSiteId', '7']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
})();
